import { getSSCID, sscidListener } from '@rei/sscid-listener';
import { SUCCESFUL_OPTOUT_STATUS } from './app-config';

export const hasAlreadyOptedOut = () => {
  const optOutCode = localStorage.getItem('optout_status');
  return !!(optOutCode === SUCCESFUL_OPTOUT_STATUS);
};

export const storeOptOutStatus = (status) => {
  localStorage.setItem('optout_status', status);
};

export const isSupportedDomain = (domain) => {
  if (domain === 'localhost') return false;

  const domainParts = domain.split('.');
  const domainBase = domainParts[domainParts.length - 2];
  return !!(domainBase === 'rei' || domainBase === 'rei-cloud');
};

export const gpcListener = async () => {
  // Don't make the request if the domain is not supported
  if (!isSupportedDomain(document.location.hostname)) return false;

  // Don't make the request if already opted out
  if (hasAlreadyOptedOut()) return false;

  let gpcValue = navigator.globalPrivacyControl || false;
  const sscid = getSSCID();

  if (gpcValue && sscid) {
    // Handle logged-in user payload
    sscidListener();

    const endpointUrl = `https://${document.location.hostname}/gpc/signals/ciam`;
    const response = await fetch(endpointUrl, {
      method: 'POST',
      priority: 'low',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ sscid }),
    });
    if (!response.ok) {
      gpcValue = false;
    }
    storeOptOutStatus(response.status);
  }
  return gpcValue;
};

export default {
  isSupportedDomain,
};
